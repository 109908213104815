<script setup lang="ts">

interface Message {
  type: string
  id?: string
  title?: string
  message?: string
  text?: string
  reason?: string
  description?: string
  statusText?: string
}

interface Props {
  messages?: Message[]
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

const types: Record<string, string> = {
  success: 'green',
  error: 'red',
  info: 'blue',
  warning: 'yellow'
}

const icons: Record<string, string> = {
  success: 'i-heroicons-check-circle',
  error: 'i-heroicons-x-circle',
  info: 'i-heroicons-information-circle',
  warning: 'i-heroicons-exclamation-circle'
}

</script>
<template>
  <div v-if="!!messages">
    <div
      v-for="(message, index) in messages"
      :key="index"
    >
      <UAlert
        :icon="icons[message.type] ?? 'i-heroicons-information-circle'"
        :color="types[message.type] ?? 'error'"
        :title="message?.title ?? ''"
        :description="message.reason || message.text || message.message || message.description || message.statusText || message"
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'white', variant: 'link', padded: false }"
        @close="() => emit('close')"
      />
    </div>
  </div>
</template>
